import React, { Component } from "react";
import LoginForm from "../../components/Form/Login";
import Header from "components/Layout/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AppContext } from "layout/AppContext";
function mapStateToProps(state) {
  return {
    store: {
      contentPage: state.languageContent.languageContent.data,
      loadingPage: state.languageContent.languageContent.loading,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({}, dispatch),
  };
};

class LoginPage extends Component {

  render() {
    const { contentPage } = this.props.store;
    const { loginPage } = contentPage.data;
    const { appContext } = this.context;
    const { config } = appContext.state;
    return (
      <div>
        <Header
          titlePage={loginPage.titlePage}
          loginPage
          contactUs={loginPage.contactUs}
          description={loginPage.description}
        />
        <div className={`${config.backgroundLine} text-center notice_1`} />
        <div className="mt-5 container pb-5">
          <LoginForm loginPage={loginPage} />
        </div>
      </div>
    );
  }
}
LoginPage.contextType = AppContext;
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
