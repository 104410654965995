/**
 * @author Tran Trung
 * @copyright Kyanon Digital
 */

import React, { PureComponent } from "react";
import Spinner from "react-spinner-material";
import { withRouter } from "react-router";
//redux
import { connect } from "react-redux";
import { AppContext } from "layout/AppContext";
import { bindActionCreators } from "redux";
import { setToken } from "providers/services";
import { loginRequest } from "redux/action/auth";
function mapStateToProps(state) {
  return {
    store: {
      auth: state.auth,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        loginRequest,
      },
      dispatch
    ),
  };
};

class VerifyLogin extends PureComponent {
  state = {
    token: null,
    errors: {},
    loginCheck: false,
    isVerify: false,
    password: "",
    email: "",
    isLogin: false,
    isLoading: false,
    isResend: false,
  };

  submitVerify = (e) => {
    e.preventDefault();
    const { loginRequest } = this.props.action;
    let { code } = this.refs;
    if (code && code.value && code.value !== "") {
      const payload = {
        username: this.props.username,
        password: this.props.password,
        mfa_code: code.value,
      };
      loginRequest(
        payload,
        (auth) => {
          if (auth.token) {
            // const { appContext } = this.context;
            const { appContext } = this.context;
            appContext.handleSetOldPassword(this.props.password);
            appContext.handleSetFirstLogin(auth.firstLogin);

            this.props.handleGetConfig();
            setToken(
              auth.token,
              auth.organizationId,
              auth.username,
              auth.status,
              auth.role,
              auth.isAuthen
            );
            this.setState({ token: auth.token });
          }
        },
        (err) => {
          this.setState({ isResend: false });
          const { auth } = this.props.store;
          const { error } = auth;
          let errors = {};
          if (error && error.non_field_errors) {
            errors["code"] = auth.error.non_field_errors;
            this.setState({
              errors: errors,
            });
          }
        }
      );
    } else {
      let errors = {};
      errors["code"] = this.props.loginPage.messageError.requiredField;
      this.setState({ errors });
    }
    this.setState({ isResend: false });
  };
  hideError = (type) => {
    let errorsCopy = Object.assign({}, this.state.errors);
    errorsCopy[type] = "";
    this.setState({ errors: errorsCopy });
  };
  receiveCode = () => {
    this.setState({ isResend: true, isLoading: true });
    const { appContext } = this.context;
    const { notifyError, notifySuccess } = appContext;
    const { loginRequest } = this.props.action;
    const payload = {
      username: this.props.username,
      password: this.props.password,
    };
    loginRequest(
      payload,
      () => {},
      (err) => {
        const { auth } = this.props.store;
        if (auth.error.mfa_required) {
          notifySuccess("Notify", this.props.loginPage.messageError.resendCode);
        } else {
          this.setState({
            isResend: false,
          });
          notifyError(
            "Notify",
            this.props.loginPage.messageError.failResendCode
          );
        }
        this.setState({
          isLoading: false,
        });
      }
    );
  };
  render() {
    const { loginPage } = this.props;
    const { appContext } = this.context;
    const { config } = appContext.state;
    return (
      <div className="row justify-content-center">
        <div className="text-center">
          <h3>
            {loginPage.titleAuth}
            <div>{loginPage.titleAuth2}</div>
          </h3>
        </div>

        <form className="form_sign_in" noValidate={true}>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <input
              type="text"
              name="code"
              placeholder={loginPage.inputCode}
              ref="code"
              className={
                "form-control " +
                (this.state.errors && this.state.errors["code"] ? "error" : "")
              }
              id="id_log"
              onChange={this.hideError.bind(this, "code")}
            />
            <label id="id_login-error" className="error" htmlFor="id_login">
              {this.state.errors["code"] && (
                <div>
                  <span>{this.state.errors["code"]}</span>
                  <br />
                </div>
              )}
            </label>
          </div>
          <div className="form-group text-center">
            <p style={{ paddingTop: "15px" }}>{loginPage.receiveOTP}</p>
            <button
              onClick={this.receiveCode}
              disabled={this.state.isResend ? "disabled" : ""}
              type="button"
              className={`btn-resend text-uppercase`}
            >
              {" "}
              {this.state.isLoading ? (
                <Spinner size={30} color={"#333"} width={2} visible={true} />
              ) : (
                loginPage.buttonResend
              )}
            </button>
          </div>
          <div className="form-group text-center">
            <button
              onClick={this.submitVerify}
              className={`btn-root text-uppercase ${config.btnRoot}`}
              type="button"
            >
              {loginPage.buttonVerify}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
VerifyLogin.contextType = AppContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VerifyLogin));
