import React, { Component } from "react";
import Header from "components/Layout/Header";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

function mapStateToProps(state) {
  return {
    store: {
      contentPage: state.languageContent.languageContent.data,
      loadingPage: state.languageContent.languageContent.loading,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators({}, dispatch),
  };
};

class NotFound extends Component {
  render() {
    const { contentPage } = this.props.store;
    const { notFoundPage } = contentPage.data;

    return <Header titlePage={notFoundPage.titlePage} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
