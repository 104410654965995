// import { fromJS, Map } from 'immutable';
import { get, omit } from 'lodash'

export const initialState = {
  listMission: {
    data: [],
    loading: true,
    total_page: 0,
  },
  missionDetail: {
    data: {},
    loading: true,
  },
  discardImage: {
    data: {},
    loading: true,
  },
  missionSummary: {
    data: {},
    loading: true,
  },
  missionCostSummary: {
    data: {},
    loading: true,
  },
  statusFile: {},
  missionElevation: {
    data: {},
    loading: true,
  },
  isUpdateStatus: {},
  listBuildingForSelect: {
    data: {},
    loading: true,
  },
  isCreateMission: {
    data: {},
    loading: true,
  },
  isRemoveMission: {
    data: {},
    loading: true,
  },
  notifyMission: {
    data: {},
    loading: true,
  },
  actionHistory: {
    data: {},
    loading: true,
  },
  actionHistoryDetails: {
    data: [],
    loading: true,
  },
  scores: {
    data: {},
    loading: true
  },
  listAcknowledgeProjects: {
    data: {},
    loading: true
  },
  informationProject: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_MISSION_LIST_REQUEST":
      return { ...state, listMission: { ...initialState.listMission } };

    case "GET_MISSION_LIST_SUCCESS": {
      const { missions, total_page } = action.payload;
      return {
        ...state,
        listMission: { data: [...missions], total_page, loading: false },
      };
    }
    case "GET_MISSION_REQUEST": {
      return { ...state, missionDetail: { ...initialState.missionDetail } };
    }
    case "GET_MISSION_SUCCESS": {
      return {
        ...state,
        missionDetail: { data: action.payload, loading: false },
      };
    }
    case "DISCARD_IMAGES_REQUEST": {
      return { ...state, discardImage: { ...initialState.discardImage } };
    }
    case "DISCARD_IMAGES_SUCCESS": {
      return {
        ...state,
        discardImage: { data: action.payload, loading: false },
      };
    }
    case "GET_MISSION_SUMMARY_REQUEST": {
      return { ...state, missionSummary: { ...initialState.missionSummary } };
    }
    case "GET_MISSION_SUMMARY_SUCCESS": {
      return {
        ...state,
        missionSummary: { data: action.payload, loading: false },
      };
    }
    case "GET_MISSION_COST_SUMMARY_REQUEST": {
      return { ...state, missionCostSummary: { ...initialState.missionCostSummary } };
    }
    case "GET_MISSION_COST_SUMMARY_SUCCESS": {
      return {
        ...state,
        missionCostSummary: { data: action.payload, loading: false },
      };
    }
    case "GET_STATUS_FILE_REQUEST": {
      return { ...state, statusFile: { ...initialState.statusFile } };
    }
    case "GET_STATUS_FILE_SUCCESS": {
      return { ...state, statusFile: action.payload };
    }
    case "GET_MISSION_ELEVATION_REQUEST": {
      return {
        ...state,
        missionElevation: { ...initialState.missionElevation },
      };
    }
    case "GET_MISSION_ELEVATION_SUCCESS": {
      return {
        ...state,
        missionElevation: { data: action.payload, loading: false },
      };
    }
    case "UPDATE_STATUS_DEFECT_REQUEST": {
      return { ...state, isUpdateStatus: { ...initialState.isUpdateStatus } };
    }
    case "UPDATE_STATUS_DEFECT_SUCCESS": {
      return { ...state, isUpdateStatus: action.payload };
    }
    case "UPDATE_MISSION_ELEVATION": {
      const {
        currentSlickIndex,
        indexDefect,
        status,
        comments,
      } = action.payload;
      let { data } = state.missionElevation;
      data[currentSlickIndex].defects[indexDefect].status = status;
      data[currentSlickIndex].defects[indexDefect].comments = comments;
      return { ...state, missionElevation: { data: data, loading: false } };
    }
    case "GET_BUILDING_SELECT_REQUEST": {
      return {
        ...state,
        listBuildingForSelect: { ...initialState.listBuildingForSelect },
      };
    }
    case "GET_BUILDING_SELECT_SUCCESS": {
      return {
        ...state,
        listBuildingForSelect: { data: action.payload, loading: false },
      };
    }
    case "CREATE_NEW_MISSION_REQUEST": {
      return { ...state, isCreateMission: { ...initialState.isCreateMission } };
    }
    case "CREATE_NEW_MISSION_SUCCESS": {
      return {
        ...state,
        isCreateMission: { data: action.payload, loading: false },
      };
    }
    case "DELETE_MISSION_REQUEST": {
      return { ...state, isRemoveMission: { ...initialState.isRemoveMission } };
    }
    case "DELETE_MISSION_SUCCESS": {
      return {
        ...state,
        isRemoveMission: { data: action.payload, loading: false },
      };
    }

    case "GET_NOTIFY_MISSION_REQUEST": {
      return { ...state, notifyMission: { ...initialState.notifyMission } };
    }
    case "GET_NOTIFY_MISSION_SUCCESS": {
      return {
        ...state,
        notifyMission: { data: action.payload, loading: false },
      };
    }

    case "GET_ACTION_HISTORY_REQUEST": {
      return { ...state, actionHistory: { ...initialState.actionHistory } };
    }
    case "GET_ACTION_HISTORY_SUCCESS": {
      return {
        ...state,
        actionHistory: { data: action.payload.data, loading: false },
      };
    }

    case "GET_LIST_ACKNOWLEDGE_PROJECT_REQUEST": {
      return { ...state, listAcknowledgeProjects: { ...initialState.listAcknowledgeProjects } };
    }
    case "GET_LIST_ACKNOWLEDGE_PROJECT_SUCCESS": {
      return {
        ...state,
        listAcknowledgeProjects: { data: action.payload, loading: false },
      };
    }

    case "GET_SCORES_PROJECT_REQUEST": {
      return { ...state, scores: { ...initialState.scores } };
    }
    case "GET_SCORES_PROJECT_SUCCESS": {
      return {
        ...state,
        scores: { data: action.payload.data, loading: false },
      };
    }

    case "GET_ACTION_HISTORY_DETAILS_REQUEST": {
      return {
        ...state,
        actionHistoryDetails: { ...initialState.actionHistoryDetails },
      };
    }
    case "GET_ACTION_HISTORY_DETAILS_SUCCESS": {
      return {
        ...state,
        actionHistoryDetails: {
          data: action.payload.data.histories,
          loading: false,
        },
      };
    }

    case "SET_ACTION_HISTORY_DETAILS": {
      return {
        ...state,
        actionHistoryDetails: {
          data: action.payload,
          loading: false,
        },
      };
    }
    case "UPDATE_DEFECT_RECTIFICATION": {
      const { elevationIndex, defectIndex, updates } = action.payload;
      let { data } = state.missionElevation;
      
      const currentDefect = get(data, `${elevationIndex}.defects.${defectIndex}`)
      if (!currentDefect) return state
      currentDefect.rectification_upload = {
        ...currentDefect.rectification_upload,
        ...omit(updates, 's3_image'),
      };
      data[elevationIndex].defects[defectIndex] = currentDefect
      return { ...state, missionElevation: { data: data, loading: false } };
    }

    case "INFO_PROJECT": {
      const { inspectionId,inspectionType } = action.payload;
      console.log("🚀 ~ file: mission.js ~ line 262 ~ inspectionId", inspectionId, inspectionType)
      return {
        ...state,
        informationProject: action.payload
      }
    }
    default:
      return state;
  }
};
