// import { fromJS, Map } from 'immutable';

export const initialState = {
  languageContent: {
    data: {},
    loading: true,
  },
  languageList: {
    data: {},
    loading: true,
  }
};

export default (state = initialState, action) => {

  switch (action.type) {
    case "GET_LANGUAGE_CONTENT_REQUEST":
      return { ...state, languageContent: { ...initialState.languageContent } };
    case "GET_LANGUAGE_CONTENT_SUCCESS":
      return { ...state, languageContent: { data: action.payload, loading: false } };
    case "GET_LANGUAGE_REQUEST":
      return { ...state, languageList: { ...initialState.languageList } };
    case "GET_LANGUAGE_SUCCESS":
      return { ...state, languageList: { data: action.payload, loading: false } };

    default:
      return state;
  }
};
