import React, { useEffect } from "react";
import useRouter from "hooks/useRouter";
import useToast from "hooks/useToast";
import { Autodesk } from "utils/Autodesk";
import AutodeskFetcher from "services/autodesk/fetcher";

const AutodeskTokenCatcher = () => {
  const router = useRouter()
  const toaster = useToast()
  useEffect(() => {
    const { access_token, expires_in, token_type } = Autodesk.extractToken();

    if(access_token && expires_in && token_type) {
      Autodesk.setToken(access_token, new Date(Date.now() + expires_in * 1000))
      AutodeskFetcher.updateToken()
    } else {
      toaster.notifyError('Something went wrong with Autodesk Authorize. Please try again later!')
      Autodesk.clearToken()
    }
    router.replace(Autodesk.getBeforeAuthPage() || '/', {
      from: 'autodesk'
    })
  }, [])

  return <></>
}

export default AutodeskTokenCatcher