import React, { Component } from "react";
import { withRouter } from "react-router";
import { AppContext } from "layout/AppContext";
class Header extends Component {
  render() {
    const { appContext } = this.context;
    const { config } = appContext.state;
    return (
      <div className={`text-center header ${config.backgroundRoot}`}>
        <div
          className={`content ${
            config.baseConfig.showBuildingHeader ? "default-building" : ""
          }`}
        >
          <h1>{this.props.titlePage}</h1>
          <p>
            {this.props.description}{" "}
            {this.props.loginPage && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.h3zoom.ai/contacts"
              >
                {this.props.contactUs}
              </a>
            )}
          </p>
        </div>
      </div>
    );
  }
}
Header.contextType = AppContext;
export default withRouter(Header);
