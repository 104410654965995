import React from 'react'

const alignGroup = {
  start: 'align-items-start',
  end: 'align-items-end',
  center: 'align-items-center',
}

const justifyGroup = {
  between: 'justify-content-between',
  center: 'justify-content-center',
}

export const FlexBox = ({
  as = 'div',
  children,
  className = '',
  align,
  justify,
  direct,
  ...rest
}) => {
  const alignClassName = align ? alignGroup[align] : ''
  const justifyClassName = justify ? justifyGroup[justify] : ''
  const directClassName = direct ? `flex-${direct}` : ''
  return (
    <div className={`d-flex ${alignClassName} ${justifyClassName} ${className} ${directClassName}`} {...rest}>
      {children}
    </div>
  )
}