// import { fromJS, Map } from 'immutable';

export const initialState = {
  isPayment: {},
  currentPlan: {},
  allPlan: {},
  historyPayment: {},
  allMethod: {
    data: {},
    loading: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CHARGE_PAYMENT_REQUEST":
      return { ...state, isPayment: { ...initialState.isPayment } };
    case "CHARGE_PAYMENT_SUCCESS": {
      return { ...state, isPayment: action.payload };
    }
    case "GET_CURRENT_PLAN_REQUEST":
      return { ...state, currentPlan: { ...initialState.currentPlan } };
    case "GET_CURRENT_PLAN_SUCCESS": {
      return { ...state, currentPlan: action.payload };
    }
    case "GET_ALL_PLAN_REQUEST":
      return { ...state, allPlan: { ...initialState.allPlan } };
    case "GET_ALL_PLAN_SUCCESS": {
      return { ...state, allPlan: action.payload };
    }
    case "GET_HISTORY_PAYMENT_REQUEST":
      return { ...state, historyPayment: { ...initialState.historyPayment } };
    case "GET_HISTORY_PAYMENT_SUCCESS": {
      return { ...state, historyPayment: action.payload };
    }
    case "GET_ALL_METHOD_REQUEST":
      return { ...state, allMethod: { ...initialState.allMethod } };
    case "GET_ALL_METHOD_SUCCESS": 
      return { ...state, allMethod: { data: action.payload, loading: false } };
    
    default:
      return state;
  }
};
