import React from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import  "./style.scss";
import { FlexBox } from "components/common/FlexBox";
import AuthStorage from "utils/AuthStorage";


const UnsubscribeNotice = ({ className, visible, onClose }) => {
  const isUnsubscribe = useSelector(state => {
    const status = get(state, 'user.user.data.status')
    if (!AuthStorage.loggedIn) return false
    if(AuthStorage.value.isSuperAdmin) return false    
    return status === 'unsubscribed'
  })

  const message = useSelector(state => {
    return get(state, 'user.user.data.message', 'Your organization is unsubscribing')
  })

  const handleCloseNotice = () => {
    onClose && onClose(false)
  }

  if (!visible || !isUnsubscribe) return <></>
  
  return (
    <div
      className={`unsubscribe-notice bg-warning ${className}`}
      style={{ padding: '3px 0'}}
    >
      <p className="text-center">
        {message}
      </p>
      <FlexBox className="close-icon" align="center">
        <i
          className="fa fa-times cursor-pointer"
          aria-hidden="true"
          onClick={handleCloseNotice}
        ></i>
      </FlexBox>
    </div>
  );
}

export default React.memo(UnsubscribeNotice)