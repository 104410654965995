// import { fromJS, Map } from 'immutable';

export const initialState = {
  
  list: {
    data: [],
    total_page: 0,
    loading: true
  },
  buildingInfo: {
    data: {},
    loading: true
  },
  listMarker: {},
  buildingSummary: {
    data: {},
    loading: true
  },
  listYearForFilter: {},
  mapImage: {
    data: {},
    loading: true
  },
  elevationBuilding: {},
  elevationBuildingCompare: {},
  listMissionOfBuilding: {},
  isCreateBuilding: {
    data: {},
    loading: true
  },
  isRemoveBuilding: {
    data: {},
    loading: true
  },
  isUploadBuilding: {
    data: {},
    loading: true
  },
  formUpload: {
    data: {},
    loading: true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_BUILDING_LIST_REQUEST":
      return { ...state, list: { ...initialState.list } };

    case "GET_BUILDING_LIST_SUCCESS": {
      const { total_page, buildings } = action.payload;
      return {
        ...state,
        list: { data: [...buildings], loading: false, total_page }
      };
    }
    case "GET_BUILDING_REQUEST": {
      return { ...state, buildingInfo: { ...initialState.buildingInfo } };
    }
    case "GET_BUILDING_SUCCESS": {
      return {
        ...state,
        buildingInfo: { data: action.payload, loading: false }
      };
    }
    case "GET_MARKER_REQUEST":
      return { ...state, listMarker: { ...initialState.listMarker } };
    case "GET_MARKER_SUCCESS": {
      return { ...state, listMarker: action.payload };
    }
    case "GET_BUILDING_SUMMARY_REQUEST":
      return { ...state, buildingSummary: { ...initialState.buildingSummary } };
    case "GET_BUILDING_SUMMARY_SUCCESS": {
      return {
        ...state,
        buildingSummary: { data: action.payload, loading: false }
      };
    }
    case "GET_MENU_FILTER_REQUEST":
      return {
        ...state,
        listYearForFilter: { ...initialState.listYearForFilter }
      };
    case "GET_MENU_FILTER_SUCCESS": {
      return { ...state, listYearForFilter: action.payload };
    }
    case "GET_MAP_IMAGE_REQUEST":
      return { ...state, mapImage: { ...initialState.mapImage } };
    case "GET_MAP_IMAGE_SUCCESS": {
      return { ...state, mapImage: { data: action.payload, loading: false } };
    }
    case "GET_ELEVATIONDETAIL_REQUEST":
      return {
        ...state,
        elevationBuilding: { ...initialState.elevationBuilding }
      };
    case "GET_ELEVATIONDETAIL_SUCCESS": {
      return { ...state, elevationBuilding: action.payload };
    }

    case "GET_ELEVATIONDETAIL_COMPARE_REQUEST":
      return {
        ...state,
        elevationBuildingCompare: { ...initialState.elevationBuildingCompare }
      };
    case "GET_ELEVATIONDETAIL_COMPARE_SUCCESS": {
      return { ...state, elevationBuildingCompare: action.payload };
    }

    case "GET_MISSIONS_REQUEST":
      return {
        ...state,
        listMissionOfBuilding: { ...initialState.listMissionOfBuilding }
      };
    case "GET_MISSIONS_SUCCESS": {      
      const listMissionOfBuilding = action.payload
      listMissionOfBuilding.sort((a,b) => a.id - b.id)
      return { ...state, 
        listMissionOfBuilding: listMissionOfBuilding, 
         };
    }
    case "CREATE_BUILDING_REQUEST":
      return {
        ...state,
        isCreateBuilding: { ...initialState.isCreateBuilding }
      };
    case "CREATE_BUILDING_SUCCESS": {
      return {
        ...state,
        isCreateBuilding: { data: action.payload, loading: false }
      };
    }

    case "DELETE_BUILDING_REQUEST":
      return {
        ...state,
        isRemoveBuilding: { ...initialState.isRemoveBuilding }
      };
    case "DELETE_BUILDING_SUCCESS": {
      return {
        ...state,
        isRemoveBuilding: { data: action.payload, loading: false }
      };
    }

    case "VERIFY_UPLOAD_REQUEST":
      return {
        ...state,
        isUploadBuilding: { ...initialState.isUploadBuilding }
      };
    case "VERIFY_UPLOAD_SUCCESS": {
      return {
        ...state,
        isUploadBuilding: { data: action.payload, loading: false }
      };
    }

    case "GET_FORM_REQUEST":
      return { ...state, formUpload: { ...initialState.formUpload } };
    case "GET_FORM_SUCCESS": {
      return { ...state, formUpload: { data: action.payload, loading: false } };
    }

    default:
      return state;
  }
};
