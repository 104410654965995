import React from "react";
import LoadingOverlay from "react-loading-overlay";
import './style.scss';

const LoadingFullScreen = () => {
  return (
    <div className="loading-full-screen full-fixed">
      <LoadingOverlay
        active={true}
        spinner
        text="Loading content of page..."
        className="loading-full-screen__loader"
      >
        <div style={{ height: "100vh" }} />
      </LoadingOverlay>
    </div>
  );
};

export default LoadingFullScreen;
