/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */
import { SINGLE_API } from "./type";

export const getLanguageContent = (payload, next, nextError) => {
  const { languageCode } = payload;
  return {
    type: SINGLE_API,
    payload: {
      uri: `lang/language_content`,
      beforeCallType: "GET_LANGUAGE_CONTENT_REQUEST",
      successType: "GET_LANGUAGE_CONTENT_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
      payment: true,
      opt: {
        headers: {
          "Accept-Language": languageCode,
        },
      },
    },
  };
};

export const getLanguage = (payload, next, nextError) => {
  return {
    type: SINGLE_API,
    payload: {
      uri: `lang/languages`,
      beforeCallType: "GET_LANGUAGE_REQUEST",
      successType: "GET_LANGUAGE_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
      payment: true,
    },
  };
};

export const getConfig = (payload, next, nextError) => {
  const { organizationId } = payload;

  return {
    type: SINGLE_API,
    payload: {
      uri: `organization/${organizationId}/config`,
      beforeCallType: "GET_CONFIG_REQUEST",
      successType: "GET_CONFIG_SUCCESS",
      afterSuccess: next,
      afterError: nextError,
    },
  };
};
