/**
 * @author Tran Trung
 * @copyright 06/06/2019 Kyanon Digital
 */

import { takeEvery, put } from "redux-saga/effects";

import { UPDATE_STORE_MISSION } from "redux/action/type.js";

function* updateStore(action) {
  if (action.type === UPDATE_STORE_MISSION) {
    const {
      type,
      afterSuccess,
      missionElevation,
    } = action.payload;
    if (type) {
      yield put({ type: type, payload: missionElevation })
    }
    if (typeof afterSuccess === "function") {
      afterSuccess(missionElevation);
    }
  }
}
export default function* () {
  yield takeEvery("*", updateStore);
}
