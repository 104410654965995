import { useContext, useMemo } from "react";
import { AppContext } from "layout/AppContext";
import { get } from "lodash";

const useToast = () => {
  const context = useContext(AppContext)
  const toaster = useMemo(() => {
    const { notifyError, notifySuccess } = get(context, "appContext", {});
    if (!notifyError) return null;
    return { notifyError, notifySuccess };
  }, [context]);
  return toaster
}

export default useToast