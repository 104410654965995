/**
 * @author Tran Trung
 * @copyright Kyanon Digital
 */

import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "layout/AppContext";
import { changePassword } from "redux/action/user";
//component
import Spinner from "react-spinner-material";

//redux
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getListGroupAssignmentRequest,
  updateAssignmentRequest,
} from "redux/action/assign";

const mapStateToProps = (state) => {
  return {
    store: {
      contentPage: state.languageContent.languageContent.data,
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getListGroupAssignmentRequest,
        updateAssignmentRequest,
        changePassword,
      },
      dispatch
    ),
  };
};

const ModalChangePassword = ({ isShow, handleClosePopup, ...props }) => {
  let [errorStatus, setErrorStatus] = useState({});

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { appContext } = useContext(AppContext);
  const { firstLogin, config, cacheOldPassword } = appContext.state;
  // Similar to compoentDidMount and compoentDidUpdate
  useEffect(() => {});
  const { contentPage } = props.store;

  const { profilePage } = contentPage.data;
  const changPasswordPage = profilePage.changPasswordPage;
  const handelSubmit = (e) => {
    // e.preventDefault();
    if (validate()) {
      const { changePassword } = props.action;
      const payload = {
        old_password: cacheOldPassword,
        new_password1: newPassword,
        new_password2: confirmPassword,
      };
      changePassword(payload, (response) => {
        if (response.detail) {
          refreshState();
          appContext.notifySuccess(
            "Notify",
            changPasswordPage.message.msgSuccessChange
          );
          appContext.handleSetFirstLogin(false);
          appContext.handleSetOldPassword("");
        } else {
          let tempError = {};
          if (response.old_password) {
            tempError["old_password"] = response.old_password;
          }
          if (response.new_password2) {
            if (
              response.new_password2[0] &&
              response.new_password2[0] ===
                "You can not use a password that is already used in this application."
            ) {
              tempError["new_password1"] = [
                changPasswordPage.message.errReusePassword,
              ];
            } else {
              tempError["new_password2"] = response.new_password2;
            }
          }
          setErrorStatus(tempError);
        }
      });
    }
  };
  const validate = () => {
    let tempError = {};
    let isValidate = true;
    if (newPassword === undefined || newPassword === "") {
      isValidate = false;
      tempError["new_password1"] = [changPasswordPage.message.requiredField];
    }
    if (confirmPassword === undefined || confirmPassword === "") {
      isValidate = false;
      tempError["new_password2"] = [changPasswordPage.message.requiredField];
    }
    if (confirmPassword !== undefined && confirmPassword !== newPassword) {
      isValidate = false;
      tempError["new_password2"] = [
        changPasswordPage.message.errConfirmPassword,
      ];
    }
    if (
      cacheOldPassword === newPassword &&
      cacheOldPassword !== undefined &&
      cacheOldPassword !== ""
    ) {
      isValidate = false;
      tempError["new_password1"] = [changPasswordPage.message.errReusePassword];
    }
    setErrorStatus(tempError);
    return isValidate;
  };
  const refreshState = () => {
    setNewPassword("");
    setConfirmPassword("");
  };

  const { popupChangePassword = {} } = contentPage.data;

  return (
    <div
      className={`modal bd-example-modal-lg fade ${firstLogin ? "show" : ""}`}
    >
      <div className="modal-dialog modal-lg popup-assign" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{popupChangePassword.titlePopup}</h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                appContext.handleSetFirstLogin(false);
              }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="alert alert-primary" role="alert">
              (*) {popupChangePassword.subtitlePopup}
            </div>
            <form className="form_sign_in">
              <div className="text-center"></div>
              <div className="form-group">
                <h5>{changPasswordPage.labelNewPassword}: </h5>
                <input
                  type="password"
                  name="new_password1"
                  placeholder={changPasswordPage.inputNewPassword}
                  className={`form-control ${
                    errorStatus["new_password1"] ? "error" : ""
                  }`}
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                  onClick={() => {
                    setErrorStatus({});
                  }}
                />
                {errorStatus["new_password1"] && (
                  <label
                    id="id_password-error"
                    className="error"
                    htmlFor="new_password1"
                  >
                    {errorStatus["new_password1"].map((item, index) => (
                      <div key={index}>
                        <span>{item}</span>
                        <br />
                      </div>
                    ))}
                  </label>
                )}
              </div>
              <div className="form-group">
                <h5>{changPasswordPage.labelConfirmPassword}: </h5>
                <input
                  type="password"
                  name="new_password2"
                  placeholder={changPasswordPage.inputConfirmPassword}
                  className={`form-control ${
                    errorStatus["new_password2"] ? "error" : ""
                  }`}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  onClick={() => {
                    setErrorStatus({});
                  }}
                />
                {errorStatus["new_password2"] && (
                  <label
                    id="id_password-error"
                    className="error"
                    htmlFor="new_password2"
                  >
                    {errorStatus["new_password2"].map((item, index) => (
                      <div key={index}>
                        <span>{item}</span>
                        <br />
                      </div>
                    ))}
                  </label>
                )}
              </div>
              <div className="form-group text-center">
                <button
                  className={`btn-root ${config.btnRoot}`}
                  type="button"
                  onClick={() => handelSubmit()}
                >
                  {changPasswordPage.btnUpdate}
                </button>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => appContext.handleSetFirstLogin(false)}
              type="button"
              className="btn btn-secondary"
            >
              {popupChangePassword.textSkip}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalChangePassword);
