import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
// import {compose} from 'redux';
// import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthStorage from "utils/AuthStorage";
import { getUserProfile, getRoleUser } from "redux/action/user"; 

function mapStateToProps(state) {
  return {
    store: {
      user: state.user,
    },
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(
      {
        getUserProfile,
        getRoleUser
      },
      dispatch
    ),
  };
};

class AuthWrap extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  componentDidMount() {
    const { getUserProfile, getRoleUser } = this.props.action;
      
    const payload = {
      organization_id: AuthStorage.organizationId,
    };
    //GET ROLE USER
    getRoleUser(payload)

    //GET INFORMATION USER
    getUserProfile(payload, (res) => {
      if (res.status) {
        let objAuth = AuthStorage.value;
        objAuth.group = res.data.group;
        AuthStorage.value = objAuth;
      }
    });
    const { history } = this.props;
    if (!AuthStorage.loggedIn) {
      history.push("/signin");
    }
  }

  render() {
    const { children } = this.props;
    if (!AuthStorage.loggedIn) {
      return null;
    }
    return children;
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthWrap)
);
