// import { fromJS, Map } from 'immutable';

export const initialState = {
  listOrganization:{
    data:{},
    loading:true
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_LIST_ORGANIZATION_REQUEST":
      return { ...state, listOrganization: { ...initialState.listOrganization } };
    case "GET_LIST_ORGANIZATION_SUCCESS": {
      return { ...state, listOrganization: action.payload };
    }
    default:
      return state;
  }
};
