import React, { useRef, useContext } from "react";
import { AppContext } from "layout/AppContext";
const ToolBarLang = ({ data = [], changeLanguage = () => {}, activeLanguage }) => {
  // Create our ref
  const refButton = useRef(null);
  const refDown = useRef(null);

  const setIsShowMenu = () => {
    refDown.current.classList.add("show");
  };

  const hiddenDropdown = () => {
    refDown.current.classList.remove("show");
  };
  const { appContext } = useContext(AppContext);
  const { config } = appContext.state;
  return (
    <div
      className="kt-header__topbar-wrapper kt-header__topbar-lang"
      onMouseLeave={hiddenDropdown}
    >
      <span
        ref={refButton}
        className="kt-header__topbar-icon"
        onClick={setIsShowMenu}
      >
        <i className="fa fa-globe fa-2x" />
      </span>
      <ul
        ref={refDown}
        className="navbar_submenu"
        style={{ maxWidth: "150px" }}
      >
        {data.map((item, index) => {
          return (
            <li key={index} className="menu-item nav-item">
              <a
                href={`#${item.code}`}
                style={{ padding: "10px" }}
                onClick={() => {
                  changeLanguage(item.code);
                  hiddenDropdown();
                }}
                className={` ${config.linkRoot} nav-link text-uppercase ${activeLanguage === item.code ? 'active' : ''}`}
              >
                {item.name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
// ToolBarLang.contextType = AppContext;
export default ToolBarLang;
